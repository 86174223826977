.page-sidebar {
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);

  .sidebar-menu {
    ul.menu-items {
      li {
        display: flex;
        align-items: center;
        padding: 1rem 0.3rem 1rem 1.5rem;
        transition: 0.3s all ease-in;

        svg {
          margin-right: 10px;
          width: 20px;
          height: auto;
        }
      }

      li.active,
      li:hover {
        background-color: #0062ff10;
        position: relative;

        &::before {
          position: absolute;
          width: 5px;
          height: 100%;
          background-color: #03a9f5;
          content: "";
          left: 0;
        }
      }

      ul.submenu {
        li{
          border-bottom: 1px solid #ffffff;
          // background-color: #ffffff;

          svg{
            color: #03a9f5;
          }
        }
        
        li.active,
        li:hover {
          border-bottom: 1px solid #03a9f5;
          color: #03a9f5;

          &::before {
            position: absolute;
            width: 5px;
            height: 100%;
            background-color: #03a9f5;
            content: "";
            left: 0;
          }
        }
      }
    }
  }

  &.showMenu {
    transform: translate3d(0, 0, 0);
  }

  @media (max-width: 1023.5px) {
    // Hide Menu
    transform: translate3d(-16rem, 0, 0);
  }
}

.hamburger {
  display: none;

  @media (max-width: 1023.5px) {
    display: block;
  }
}

.profile-box {
  display: block;

  @media (max-width: 1023.5px) {
    display: none;
  }
}
