@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bg-color: 244, 247, 252;
    --primary-color: 3, 169, 245;
    --light-primary: 237,249,254;
    --text-color: 112, 112, 112;
    --title-text-color: 52, 52, 52;
    --custom-dark: 67,66,93;
    --footer-text-color: 128, 140, 159;
    --link-text-color: 150, 150, 150;
    --border-color: 209, 209, 209;
    --bg-light: 202,211,226;
    --bg-green: 58,204,10;
    --bg-red:255,35,103;
    --light-green: 167, 192, 74;
    --light-gray: 240, 240, 240;
    --bg-yellow:255,240,65;
    --card-lightred: 255,217,229;
    --card-red: 255,132,169;
    --card-lightblue: 224,236,255;
    --card-blue:97,161,255;
    --card-lightyellow: 255,249,225;
    --card-yellow: 255,224,111;
    --card-lightgreen: 212,244,232;
    --card-green: 14,191,125;
  }

  .dark {
    --primary-color: 132, 29, 57;
  }
}

body {
  margin: 0;
  font-size: 0.8rem;
  font-family: "Nunito", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden !important;
}

.table-row {
  @apply odd:bg-white even:bg-gray-100;
}

.table-cell {
  @apply px-3 py-4 first:rounded-l-md last:rounded-r-md;
}
