.show {
  transform: translate3d(0, 0, 0) !important;
}

.side-menu {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  transform: translate3d(100%, 0, 0);
}

.category-menu {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  transform: translate3d(-100%, 0, 0);
}

.admin-category-menu {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);

  @media (max-width: 767.5px) {
    // Hide Menu
    transform: translate3d(-100%, 0, 0);
  }
}
